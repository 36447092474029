import {createTheme} from '@material-ui/core'

const Theme = createTheme({
	palette : {
		pink: {
			darker: '#ff5aaa',
			dark: '#FF85C0',
			main: '#FFEFF6'
		}
	}
})

export default Theme;