import { useEffect, useRef, useState } from "react";
import Engine from "../game/Engine";


const wrapperStyle = {
  width:'100%',
  height:'100vh',
  minHeight:1300,
  display:'flex',
  backgroundColor:'lightgray'
}

const canvasWrapperStyle = {
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  width:'100%',
}

const FruitStand = () => {
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [canvasLoaded, setCanvasLoaded] = useState(false);

  useEffect(() => {
    if (!canvasRef.current) return;
    setCanvas(canvasRef.current);
    setCanvasLoaded(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!canvasLoaded) return;
    const game = new Engine(canvas, canvas.getContext('2d'));
    game.init();
    game.run();
    return () => {
      console.log('dismount');
      game.cleanup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasLoaded])

  return (
    <div style={wrapperStyle}>
      <link href='https://fonts.googleapis.com/css?family=Quicksand' rel='stylesheet'></link>
      <div style={canvasWrapperStyle}>
        <canvas ref={canvasRef} id='canvas'>
        </canvas>
      </div>
      <img src="game/endOfDay.png" id="endOfDay" alt='person' style={{display:"none"}} />
      <img src="game/leaderboardMenu.png" id="leaderboard" alt='person' style={{display:"none"}} />
      <img src="game/wholesaleMenu.png" id="wholesaleMenu" alt='person' style={{display:"none"}} />
      <img src="game/dollarSign.png" id="dollarSign" alt='person' style={{display:"none"}} />
      <img src="game/person1.png" id="person1" alt='person' style={{display:"none"}} />
      <img src="game/person2.png" id="person2" alt='person' style={{display:"none"}} />
      <img src="game/person3.png" id="person3" alt='person' style={{display:"none"}} />
    </div>
  )
}

export default FruitStand;
