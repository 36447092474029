import GameObject from "../GameObject";
import { GAME_GLOBALS } from "../Globals";


export default class DollarSign extends GameObject {

  constructor() {
    super(0, 0, 58, 62, 'none');
    this.sprite = document.getElementById('dollarSign');
    this.x = GAME_GLOBALS.WIDTH / 2 - this.w / 2;
    this.y = 440;
    this.despawn = 400;
    this.setToDelete = false;
  }

  update() {
    this.y -= 0.5;
    if (this.y < this.despawn){
      this.setToDelete = true;
    }
  }
}