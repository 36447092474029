import React from 'react'
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import NavLinks from './NavLinks';


const useStyles = makeStyles((theme) => ({
	wrapper : {
		maxWidth: '100vw',
		minWidth: '1300px'
	},
	nav : {
		display: 'flex',
  	justifyContent: 'space-between',
  	alignItems: 'center',
  	padding: '1rem 4rem',
  	backgroundColor: '#FFFFFF',
  	boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
		[theme.breakpoints.down('md')]:{
			padding: '1rem 2rem'
		}
	},
	navImageWrapper: {
		display: 'flex',
		justifyContent: 'left',
		paddingLeft: '2rem',
		[theme.breakpoints.down('md')]:{
			paddingLeft: '0.5rem'
		}
	},
	navImage : {
		width: '273px',
		height: '83px'
	},
}));


function Nav() {
	const classes = useStyles();
	return (
		<div className={classes.wrapper}>
			<div className={classes.nav}>
				<Grid container justifyContent='space-evenly' alignItems='center'>
					<Grid item lg={6} md={4} className={classes.navImageWrapper}>
						<img src='/header_logo.png' className={classes.navImage} alt='Totally Creative'></img>
					</Grid>
					<Grid item lg={6} md={8}>
						<NavLinks />
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default Nav