import { GAME_GLOBALS } from "./Globals";


export default class GameObject {

  constructor (x, y, w, h, imgSrc) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.rotation = 0;
    this.crop = null;
    this.imgSrc = imgSrc;
    this.hover = false;
    this.sprite = null;
  }

  setRotation(deg) {
    this.rotation = deg*Math.PI/180;
  }

  setCrop({ x, y, w, h }) {
    this.crop = {
      x:x,
      y:y,
      w:w,
      h:h,
    }
  }

  collidePoint(x, y) {
    return (x > this.x 
    && x < this.x + this.w)
    && (y > this.y
    && y < this.y + this.h);
  }

  collideRect(x, y, w, h) {
    return (
      this.collidePoint(x, y) ||
      this.collidePoint(x + w, y) ||
      this.collidePoint(x, y + h) ||
      this.collidePoint(x + w, y + h)
    );
  }

  /**
   * 
   * @param {CanvasRenderingContext2D} ctx 
   */
  drawCollider(ctx) {
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 3;
    ctx.strokeRect(this.x, this.y, this.w, this.h);
    ctx.beginPath();
    ctx.moveTo(this.x, this.y);
    ctx.lineTo(this.x + this.w, this.y + this.h);
    ctx.moveTo(this.x + this.w, this.y);
    ctx.lineTo(this.x, this.y + this.h);
    ctx.stroke()
    ctx.closePath();
  }

  async init() {
    if (!this.imgSrc) return;
    this.sprite = new Image();
    this.sprite.src = this.imgSrc;
    this.sprite.crossOrigin = 'Anonymous'
    let loaded = false;
    this.sprite.onload = () => { loaded = true }
    while (!loaded) {
      await new Promise(r => setTimeout(r, 500));
    }
  }

  update() {
    
  }

  /**
   * 
   * @param {CanvasRenderingContext2D} ctx 
   */
  draw(ctx) {
    ctx.save();
    // ctx.translate(this.x + this.w / 2, this.y + this.height / 2);
    // ctx.rotate(this.rotation);
    // ctx.translate(- this.x - this.w  / 2, - this.y - this.h / 2);
    if (this.sprite) {
      if (!this.crop) ctx.drawImage(this.sprite, this.x, this.y, this.w, this.h);
      else ctx.drawImage(this.sprite, this.crop.x, this.crop.y,
        this.crop.w, this.crop.h, this.x, this.y, this.w, this.h);
    }
    if ((this.hover || !this.sprite) && GAME_GLOBALS.DEV_MODE) {
      this.drawCollider(ctx);
    }
    ctx.restore();
  }

}