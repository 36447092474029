import Game from "./gameStates/Game";
import MainMenu from "./gameStates/MainMenu";

export const GameStates = {
  'main': new MainMenu(),
  'game': new Game(),
}

export const GAME_GLOBALS = {
  WIDTH: 840,
  HEIGHT: 1200,
  MOUSE_POS: {
    x: null,
    y: null
  },
  KEYS: {},
  DEV_MODE: true,
  OFFSET: {
    left: null,
    top: null,
  },
  CLICK: true,
  GAME_STATE: 'main',
}