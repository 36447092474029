import GameObject from "../GameObject";
import Button, { IconButton } from "./Button";

export default class TutorialBox extends GameObject {

  /** @param {CanvasRenderingContext2D} ctx */
  drawBackground = (ctx) => {
    ctx.save();
    ctx.fillStyle = 'rgba(255,255,255,0.95)';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2
    ctx.beginPath();
    ctx.roundRect(this.x, this.y, this.w, this.h, [30]);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.beginPath();
    ctx.moveTo(this.x + 16, this.y + 80);
    ctx.lineTo(this.x + 16 + 345, this.y + 80)
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }

  /** @param {CanvasRenderingContext2D} ctx */
  drawStepCounter = (ctx) => {
    ctx.save();
    ctx.fillStyle = 'black'
    ctx.font = '500 20px/24px Quicksand';
    ctx.textBaseline = 'middle'
    ctx.fillText(`${this.currentStep}/${this.totalSteps}`, this.x + 469, this.y + 235)
    ctx.restore();
  }

  /** @param {CanvasRenderingContext2D} ctx */
  drawHeading(ctx, text) {
    ctx.save();
    ctx.fillStyle = 'black'
    ctx.font = '700 36px/24px Quicksand';
    ctx.textBaseline = 'middle'
    ctx.fillText(text, this.x + 23, this.y + 44)
    ctx.restore();
  }

  /** @param {CanvasRenderingContext2D} ctx */
  drawText(ctx, lines, size, x, y) {
    ctx.save();
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'middle'
    let xOffset = x;
    let yOffset = y;
    let lineHeight = 24;
    lines.forEach((line, idx) => {
      let bold = !line.indexOf('*b*') === 0;
      let lineSplits = line.split('*b*');
      xOffset = x;
      yOffset = y + (lineHeight * 2) * idx;
      lineSplits.forEach((split) => {
        ctx.font = `${bold ? '700' : '500'} ${size}px/${lineHeight}px Quicksand`;
        ctx.fillText(split, xOffset, yOffset);
        xOffset = xOffset + ctx.measureText(split).width;
        bold = !bold;
      })
    })
    ctx.restore();
  }

  drawSteps = [
    (ctx) => {
      const lines = [
        'Congratulations! You have opened your very own *b*FRUIT STAND!*b*',
        'Your goal is to make as much money as you can in *b*7 days.*b*'
      ]
      this.drawHeading(ctx, 'Getting Started');
      this.drawText(ctx, lines, 20, this.x + 20, this.y + 111);
    },
    (ctx) => {
      const lines = [
        'Each day begins in *b*Wholesale Mode*b*.',
        'In Wholesale Mode, you buy your *b*fruit*b* for the day!'
      ]
      this.drawHeading(ctx, 'Starting the Day');
      this.drawText(ctx, lines, 20, this.x + 20, this.y + 111);
    },
    (ctx) => {
      const lines = [
        'Before you can sell, you need to *b*buy inventory!*b*',
        '*b*Click Next*b* to purchase fruit wholesale.'
      ]
      this.drawHeading(ctx, 'Inventory');
      this.drawText(ctx, lines, 20, this.x + 20, this.y + 111);
    },
  ]

  #initButtons() {
    this.forwardBtn.onClick = () => {
      if (this.currentStep < this.totalSteps) this.currentStep++;
      else if (this.currentStep === this.totalSteps) this.isOpen = false;
    }
    this.backBtn.onClick = () => {
      if (this.currentStep > 1) this.currentStep--;
    }
    this.skipBtn.onClick = () => {
      this.currentStep = 1;
      this.isOpen = false;
    }
  }

  constructor() {
    super(57, 461, 721, 271, null, 5);
    this.backBtn = new Button(this.x + 20, this.y + 210, 181, 50, 'red', 'BACK', 22, true);
    this.forwardBtn = new Button(this.x + 517, this.y + 210, 181, 50, 'blue', 'NEXT', 22, true);
    this.skipBtn = new IconButton(this.x + 675, this.y + 10, 33, 33, 'black', true, 'game/iconClose.png');
    this.totalSteps = 3;
    this.currentStep = 1;
    this.isOpen = true;
    this.#initButtons();
  }

  async init() {
    console.log('Initializing Tutorial...')
    const initList = [];
    initList.push(this.skipBtn.init());
    await Promise.all(initList);
    console.log('Tutorial Initialized...')
  }

  update() {
    if (this.currentStep > 1) this.backBtn.update();
    this.forwardBtn.update();
    this.skipBtn.update();
  }

  /**
   * 
   * @param {CanvasRenderingContext2D} ctx 
   */
  draw(ctx) {
    this.drawBackground(ctx);
    if (this.currentStep > 1) this.backBtn.draw(ctx);
    this.forwardBtn.draw(ctx);
    this.skipBtn.draw(ctx);
    this.drawText(ctx, ['Skip Tutorial?'], 18, this.x + 555, this.y + 28);
    this.drawStepCounter(ctx);
    this.drawSteps[this.currentStep - 1](ctx);
  }

  cleanup() {
    this.currentStep = 1;
    this.isOpen = true;
  }
}