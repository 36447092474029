import React from 'react'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display:'flex',
		height:'auto',
		width:'auto',		
		alignItems:'center',
		justifyContent:'center',
		flexDirection:'column',
		textDecoration:'none',
		color:'black',
		[theme.breakpoints.down('lg')]:{
			marginTop:'1rem'
		}
	},
	iconContainer: {
		width:'115px',
		height:'115px',
		display:'flex',
		[theme.breakpoints.up('lg')]:{
			width:'150px',
			height:'150px'
		},
		'& img': {
			width:'100%',
			height:'100%'
		}
	},
	creditText: {
		fontFamily:'raleway',
		fontWeight:'700',
		fontSize:'8px',
		marginTop:'0.25rem',
		'& span': {
			color: theme.palette.pink.dark
		},
		[theme.breakpoints.up('lg')]:{
			fontSize:'10px'
		}
	},
	portfolioLabelText: {
		fontFamily: 'Raleway',
		fontSize: '24px',
		fontWeight: '300',
		lineHeight: '28px',
		textAlign: 'center',
		marginTop:'1.5rem',
		paddingBottom:'0.25rem',
		[theme.breakpoints.up('lg')]:{
			fontSize:'30px',
			marginTop:'2rem',
			paddingBottom:'0.25rem'
		}

	}

}))

function PortfolioCreditText(){
	const classes = useStyles();
	return (
		<div className={classes.creditText}>
			Designed by <span>Freepik</span>
		</div>
	)
}

function PortfolioImageLink(props) {
	const classes = useStyles();
	return (
		<a className={classes.wrapper} href={props.url}>
			<div className={classes.iconContainer}>
				<img src={props.image} alt={props.text} />
			</div>
			<PortfolioCreditText />
			<div className={classes.portfolioLabelText}>
				<span>{props.text.toUpperCase()}</span>
			</div>
		</a>
	)
}

export default PortfolioImageLink