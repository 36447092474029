import React from 'react'
import {makeStyles, Grid} from '@material-ui/core'
import globalUseStyles from '../helpers/GolbalClasses';
import SectionHeader from '../components/SectionHeader';
import SectionContentHeader from '../components/SectionContentHeader';
import useSetTitle from '../helpers/useSetTitle';

const useStyles = makeStyles((theme) => ({
	podcastEmbed: {
		border: 'none',
		marginTop: '3rem',
		width: '80%'

	},
	podcastWrapper: {
		display:'flex',
		justifyContent:'center'
	}
}))


function Podcasts() {
	const classes = useStyles();
	const globalClasses = globalUseStyles();
	useSetTitle('Podcasts');
	return (
		<div>
			<SectionHeader text='Podcasts' />
			<section className={globalClasses.sectionWrapper}>
				<Grid container>
					<Grid sm={12}>
						<SectionContentHeader text='POSITIVE FORWARD MOTION' />
						<div className={globalClasses.sectionText}>
							As the Social Media Manager for the Positive Forward Motion Podcast from 2019 to the present, I have conceptualized, written, edited, scheduled, and promoted dozens of episodes. I’d be as bold as to call myself a podcast expert!
							<br />
							<br />
							Here is a small selection of Positive Forward Motion podcast episodes I have had the pleasure of working on:
						</div>
					</Grid>
				</Grid>
				<div className={classes.podcastWrapper}>
					<iframe title="Libsyn Player" className={classes.podcastEmbed} src="//html5-player.libsyn.com/embed/episode/id/20714270/height/90/theme/custom/thumbnail/yes/direction/forward/render-playlist/no/custom-color/005b97/" height="90" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
				</div>
					<div className={classes.podcastWrapper}>
						<iframe title="Libsyn Player" className={classes.podcastEmbed} src="//html5-player.libsyn.com/embed/episode/id/20631239/height/90/theme/custom/thumbnail/yes/direction/forward/render-playlist/no/custom-color/005b97/" height="90" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
					</div>
					<div className={classes.podcastWrapper}>
						<iframe title="Libsyn Player" className={classes.podcastEmbed} src="//html5-player.libsyn.com/embed/episode/id/14393627/height/90/theme/custom/thumbnail/yes/direction/forward/render-playlist/no/custom-color/005b97/" height="90" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
					</div>
					<div className={classes.podcastWrapper}>
						<iframe title="Libsyn Player" className={classes.podcastEmbed} src="//html5-player.libsyn.com/embed/episode/id/13429136/height/90/theme/custom/thumbnail/yes/direction/forward/render-playlist/no/custom-color/005b97/" height="90" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
					</div>
					<div className={classes.podcastWrapper}>
					<iframe title="Libsyn Player" className={classes.podcastEmbed} src="//html5-player.libsyn.com/embed/episode/id/13020578/height/90/theme/custom/thumbnail/yes/direction/forward/render-playlist/no/custom-color/005b97/" height="90" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
					</div>
					<div className={classes.podcastWrapper}>
						<iframe title="Libsyn Player" className={classes.podcastEmbed} src="//html5-player.libsyn.com/embed/episode/id/12652160/height/90/theme/custom/thumbnail/yes/direction/forward/render-playlist/no/custom-color/005b97/" height="90" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
					</div>
				

				
			</section>
		</div>
	)
}

export default Podcasts