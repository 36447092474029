import React from 'react'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	sectionWrapper: {
		display: 'flex',
		width:'100%',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.pink.main
	},
	sectionText: {
		fontFamily: 'Monoton',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '100px',
		color: theme.palette.pink.dark
	}
}));


function SectionHeader(props) {
	const classes = useStyles()
	return (
		<div className={classes.sectionWrapper}>
			<div className={classes.sectionText}>
				{props.text}
			</div>
		</div>
	)
}

export default SectionHeader