import { getCurrentTimeSecs } from "../../helpers/currentTimeSecs";
import GameObject from "../GameObject";
import { GAME_GLOBALS } from "../Globals";

export default class Person extends GameObject {

  constructor (x, y, w, h, imgSrc, animCrop, animSpeed, frames, sprite) {
    super(x, y, w, h, imgSrc);
    if (sprite) this.sprite = sprite;
    this.waitTime = 2;
    this.arrivalTime = 0;
    this.waiting = false;
    this.complete = false;
    this.animCrop = animCrop;
    this.animSpeed = animSpeed;
    this.frames = frames || 0;
    this.lastFrameChange = Date.now();
    this.currentFrame = 0;
    if (this.frames > 0) this.setCrop(this.animCrop);
  }

  update(game) {
    super.update();
    if (!this.waiting) {
      this.x += 2;
      if (this.frames > 0 && this.lastFrameChange + this.animSpeed < Date.now()) {
        // Update animation
        this.currentFrame = this.currentFrame + 1 >= this.frames ? 0 : this.currentFrame + 1;
        this.setCrop({
          x:this.animCrop.x + (this.animCrop.w * this.currentFrame),
          y:this.animCrop.y,
          w:this.animCrop.w,
          h:this.animCrop.h
        });
        this.lastFrameChange = Date.now();
      }
    }

    // Wait for all people to leave before completing the day
    if (game.dayComplete) {
      this.waiting = false;
      return;
    }
    if (this.x + this.w / 2 >= GAME_GLOBALS.WIDTH / 2 && !this.complete && !this.waiting){
      this.arrivalTime = getCurrentTimeSecs();
      this.waiting = true;
    }

    if (this.waiting && !this.complete && this.arrivalTime + this.waitTime < getCurrentTimeSecs()) {
      if (Math.floor(Math.random() * 100) < 75) {
        const availableProducts = game.inventory.reduce((newArr, item) => {
          if (item.stock > 0) newArr.push(item);
          return newArr;
        }, []);
        if (!availableProducts.length) game.dayComplete = true;
        else {
          // Choose a product
          const buyIndex = Math.floor(Math.random() * availableProducts.length);
          const productToBuy = game.inventory.find((item) => {
            return availableProducts[buyIndex].name === item.name;
          })
          game.money += productToBuy.sell;
          productToBuy.stock -= 1;
          game.soldThisDay[productToBuy.name] += 1;
          game.triggerPurchase();
        }
      }
      this.complete = true;
      this.waiting = false;
    }
  }

  draw(ctx) {
    super.draw(ctx);
  }

}