import React from 'react'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	content: {
		fontFamily: 'raleway',
		fontSize:'32px',
		fontWeight:'300',
		display:'inline-block'
	}
}))


function SectionContentSubHeader(props) {
	const classes = useStyles()
	return (
		<div className={classes.content}>
			{props.text}
		</div>
	)
}

export default SectionContentSubHeader