import { GAME_GLOBALS, GameStates } from "./Globals";

export default class Engine {
  constructor (canvas, ctx) {
    /** @type {HTMLCanvasElement} */ 
    this.canvas = canvas;
    /** @type {CanvasRenderingContext2D} */
    this.ctx = ctx;
    this.gameState = 'main'
    this.gameStateInitialized = false;
    this.setToClose = false;
    this.animationId = '';
    this.lastFrame = null;
    this.startTime = null;
    this.fpsInterval = 1000 / 60 // 60 FPS
    this.frameCount = 0;
  }

  async init() {
    this.canvas.width = GAME_GLOBALS.WIDTH;
    this.canvas.height = GAME_GLOBALS.HEIGHT;
    this.ctx.clearRect(0, 0, GAME_GLOBALS.WIDTH, GAME_GLOBALS.HEIGHT);
    GAME_GLOBALS.OFFSET = this.canvas.getBoundingClientRect();
    this.registerControls();
    for (const state in GameStates) {
      await GameStates[state].init(this);
    }
    console.log('Initialization Complete.')
  }

  static setMousePos(evt) {
    GAME_GLOBALS.OFFSET = document.getElementById('canvas').getBoundingClientRect();
    GAME_GLOBALS.MOUSE_POS = {
      x: evt.x - GAME_GLOBALS.OFFSET.left,
      y: evt.y - GAME_GLOBALS.OFFSET.top
    }
  }

  static keyDown(evt) {
    GAME_GLOBALS.KEYS[evt.key] = true;
  }

  static keyUp(evt) {
    if (GAME_GLOBALS.KEYS[evt.key]) GAME_GLOBALS.KEYS[evt.key] = false;
  }

  static click() {
    GAME_GLOBALS.CLICK = true;
  }

  registerControls() {
    console.log('Registering controls...')
    window.addEventListener('mousemove', Engine.setMousePos);
    window.addEventListener('keydown', Engine.keyDown);
    window.addEventListener('keyup', Engine.keyUp);
    window.addEventListener('click', Engine.click);
  }

  deregesterControls() {
    console.log('Deregistering controls...')
    window.removeEventListener('mousemove', this.setMousePos);
    window.removeEventListener('keydown', this.keyDown);
    window.removeEventListener('keyup', this.keyup);
    window.removeEventListener('click', Engine.click);
  }

  update() {
    if (GAME_GLOBALS.KEYS['Escape']) {
      this.setToClose = true;
      return;
    }
    if (GAME_GLOBALS.GAME_STATE !== this.gameState) {
      GameStates[this.gameState].cleanup();
      this.gameState = GAME_GLOBALS.GAME_STATE;
    }
  }

  draw(ctx) {
    ctx.clearRect(0, 0, GAME_GLOBALS.WIDTH, GAME_GLOBALS.HEIGHT);
  }

  async run() {
    if (this.setToClose) {
      this.cleanup();
      return;
    }
    this.animationId = window.requestAnimationFrame(() => { this.run() });

    const now = Date.now();
    if (!this.startTime) this.startTime = now;

    let deltaTime = now - this.lastFrame;

    if (deltaTime > this.fpsInterval) {
      this.lastFrame = now - (deltaTime % this.fpsInterval)
      this.update();
      this.draw(this.ctx);
      GameStates[GAME_GLOBALS.GAME_STATE].update(this);
      GameStates[GAME_GLOBALS.GAME_STATE].draw(this.ctx);
      GAME_GLOBALS.CLICK = false;
    }
  }

  cleanup() {
    console.log('Clean up');
    this.deregesterControls();
    window.cancelAnimationFrame(this.animationId);
  }
}