import GameObject from "../GameObject";
import { GAME_GLOBALS } from "../Globals";

const colorSchemes = { 
  red: {
    bg: '#FF595E',
    border: '#000000',
    text: '#000000'
  },
  blue: {
    bg: '#45A0DA',
    border: '#000000',
    text: '#000000'
  },
  green: {
    bg: '#8AC926',
    border: '#000000',
    text: '#000000'
  },
  black: {
    bg: '#000000',
    border: '#000000',
    text: '#000000'
  },
  disabled: {
    bg: '#A3A3A3',
    border: '#505050',
    text: '#505050'
  }
}

export default class Button extends GameObject {

  constructor (x, y, w, h, color, text, fontSize, isActive) {
    super(x, y, w, h, null);
    this.color = colorSchemes[color];
    this.text = text;
    this.fontSize = fontSize;
    this.isActive = isActive || false;
    this.centerX = this.x + this.w / 2;
    this.centerY = this.y + this.h / 2;
    this.onClick = () => { console.log('Clicked ', this.text) }
  }

  update() {
    let {x: mX, y: mY } = GAME_GLOBALS.MOUSE_POS;
    if (this.collidePoint(mX, mY)) this.hover = true;
    else this.hover = false;
    if (this.hover && GAME_GLOBALS.CLICK && this.isActive) {
      this.onClick();
      GAME_GLOBALS.CLICK = false;
    }
  }

  setActive (state) {
    this.isActive = state;
  }

  /** @param {CanvasRenderingContext2D} ctx */
  draw(ctx) {
    const style = this.isActive ? this.color : colorSchemes.disabled;
    ctx.save();
    ctx.fillStyle = style.bg;
    ctx.strokeStyle = style.border;
    ctx.lineWidth = 3
    ctx.beginPath();
    ctx.roundRect(this.x, this.y, this.w, this.h, [16]);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.fillStyle = style.text;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle"
    ctx.font = `700 ${this.fontSize}px Quicksand`
    ctx.fillText(this.text, this.centerX, this.centerY);
    ctx.restore();
  }

}

export class IconButton extends Button { 
  constructor (x, y, w, h, color, isActive, iconSrc) {
    super(x, y, w, h, color, '', '', isActive);
    this.iconSrc = iconSrc;
    this.icon = null;
  }

  async init() {
    if (!this.iconSrc) return;
    this.icon = new Image();
    this.icon.src = this.iconSrc;
    this.icon.crossOrigin = 'Anonymous'
    let loaded = false;
    this.icon.onload = () => { loaded = true }
    while (!loaded) {
      await new Promise(r => setTimeout(r, 500));
    }
    this.updateColor(this.color)
  }

  updateColor() {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = this.w;
    tempCanvas.height = this.h;
    const tempCtx = tempCanvas.getContext('2d');
    const style = this.isActive ? this.color : colorSchemes.disabled;
    tempCtx.fillStyle = style.bg;
    tempCtx.fillRect(0, 0, this.w, this.h);
    tempCtx.globalCompositeOperation = "destination-in";
    tempCtx.drawImage(this.icon, 0, 0, this.w, this.h);
    this.icon = tempCanvas;
  }

  /** @param {CanvasRenderingContext2D} ctx */
  draw(ctx) {
    ctx.save();
    ctx.drawImage(this.icon, this.x, this.y, this.w, this.h);
    ctx.restore();
  }

  setActive(state) {
    super.setActive(state);
    this.updateColor();
  }

}