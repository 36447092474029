import React from 'react'
import {makeStyles, Grid} from '@material-ui/core'
import globalUseStyles from '../helpers/GolbalClasses';
import SectionHeader from '../components/SectionHeader';
import SectionContentHeader from '../components/SectionContentHeader';
import SectionContentSubHeader from '../components/SectionContentSubHeader';
import useSetTitle from '../helpers/useSetTitle';
const useStyles = makeStyles((theme) => ({
	youtubeEmbed : {
		width:'100%',
		height:'360px',
		[theme.breakpoints.down('lg')]:{
			height: '280px'
		},
		[theme.breakpoints.up('lg')]:{
			height: '360px'
		},
		[theme.breakpoints.up('xl')]:{
			height: '415px'
		},

	}
}))


function VideoEditing() {
	const classes = useStyles();
	const globalClasses = globalUseStyles();
	useSetTitle('Video Editing');
	return (
		<div>
			<SectionHeader text='Video Editing' />
			<section className={globalClasses.sectionWrapper}>
				<Grid container>
					<Grid sm={5}>
						<SectionContentHeader text='POSITIVE FORWARD MOTION' />
						<br />
						<br />
						<SectionContentSubHeader text='Podcast Videos' />
						<div className={globalClasses.sectionText}>
						Along with writing and editing the traditional audio podcast episodes for Positive Forward Motion, I also instituted YouTube video versions of the podcast as well.
						<br />
						<br />
						<a href='https://www.youtube.com/channel/UCE04EVOb1nHMiaC_QSy7c9w' rel='noreferrer' target="_blank" className={globalClasses.sectionLink}>YOUTUBE.COM/DENISESCATTERGOOD</a>
						</div>
					</Grid>
					<Grid item sm={2}></Grid>
          <Grid item sm={5}>  
            <div className={globalClasses.sectionImage}>
						<iframe className={classes.youtubeEmbed} src="https://www.youtube.com/embed/3YsLx_x6pDI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Grid>
				</Grid>
			</section>
			<section className={globalClasses.sectionWrapper}>
				<Grid container>
					<Grid item sm={5}>  
            <div className={globalClasses.sectionImage}>
						<iframe className={classes.youtubeEmbed} src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6917865618922766336?compact=1" scrolling='no' frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
            </div>
          </Grid>
					<Grid item sm={2}></Grid>
					<Grid sm={5}>
						<SectionContentHeader text='DENISE SCATTERGOOD' />
						<br />
						<br />
						<SectionContentSubHeader text='LinkedIn Videos' />
						<div className={globalClasses.sectionText}>
						Repurposing older unedited content filmed before my time with Alert Trainers, I was able to create a variety of short-form videos primarily 
						for <a href='https://www.linkedin.com/in/alerttrainers?trk=public_post_share-update_actor-text' rel='noreferrer' target="_blank" className={globalClasses.sectionLink}>LinkedIn</a>, 
						but also for InstagramTV, Reels, and Tiktok.
						</div>
					</Grid>
				</Grid>
			</section>
			<section className={globalClasses.sectionWrapper}>
				<Grid container>
					<Grid sm={5}>
						<SectionContentHeader text='LINDSEY ERSTAD YOUTUBE' />
						<br />
						<br />
						<SectionContentSubHeader text='Comedy Videos' />
						<div className={globalClasses.sectionText}>
						I have extensive video editing experience from previously running my
						own <a href='https://www.youtube.com/totallylindsey' rel='noreferrer' target="_blank" className={globalClasses.sectionLink}>YouTube channel</a> with
						19,000+ subscribers at its peak. Though I no longer actively post to this channel,
						it is a great reflection of not only my experience but my growth as a video editor.
						</div>
					</Grid>
					<Grid item sm={2}></Grid>
          <Grid item sm={5}>  
						<iframe className={classes.youtubeEmbed} src="https://www.youtube.com/embed/DbBhiB-ks-U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </Grid>
				</Grid>
			</section>
		</div>
	)
}

export default VideoEditing