import GameObject from "../GameObject";
import { GAME_GLOBALS } from "../Globals";

export default class GameUI {

  async init(game) {
    const initList = []
    this.homeIcon = new GameObject(67, 1116, 47, 43, 'game/homeIcon.png');
    this.plusIcon = new GameObject(485, 1160, 28, 28, 'game/plusIcon.png');
    this.tutorialBgColor = '#AFB1B6';
    this.tutorialTextColor = '#505050';
    this.currentMoney = game.money;
    this.timeDisplay = this.getTimeDisplay(game.timeRemaining);
    initList.push(this.homeIcon.init());
    initList.push(this.plusIcon.init());
    await Promise.all(initList);
  }

  /** @param {CanvasRenderingContext2D} ctx */
  drawMoney(ctx) {
    const rectW = 315;
    const rectH = 135;
    ctx.save();
    ctx.fillStyle = 'white';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.roundRect(-1, -1, rectW, rectH, [0, 0, 30, 0]);
    ctx.fill()
    ctx.stroke();
    ctx.closePath();
    ctx.font = '700 40px/24px Quicksand'
    ctx.fillStyle = 'black';
    ctx.textBaseline = 'top'
    ctx.fillText('MONEY SAVED', 17, 16)
    const moneyText = `$${this.currentMoney.toFixed(2)}`;
    const xOffset = ctx.measureText(moneyText).width / 2
    ctx.fillText(moneyText, rectW / 2 - xOffset, 72)
    ctx.restore();
  }

  getTimeString() {
    return 
  }

  /** @param {CanvasRenderingContext2D} ctx */
  drawDay(ctx) {
    const rectW = 315;
    const rectH = 135;
    const rectX = 526;
    ctx.save();
    ctx.fillStyle = 'white';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.roundRect(rectX, -1, rectW, rectH, [0, 0, 0, 30]);
    ctx.fill()
    ctx.stroke();
    ctx.font = '700 40px/24px Quicksand'
    ctx.fillStyle = 'black';
    ctx.textBaseline = 'top'
    const textSize = ctx.measureText('DAY ONE')
    let xOffset = textSize.width / 2
    ctx.fillText('DAY ONE', rectX + (rectW / 2 - xOffset), 16)
    xOffset = ctx.measureText(`${this.timeDisplay} mins`).width / 2
    ctx.fillText(`${this.timeDisplay} mins`, rectX + (rectW / 2 - xOffset), 72)
    ctx.closePath();
    ctx.restore();
  }
  
  /** @param {CanvasRenderingContext2D} ctx */
  drawHome(ctx) {
    const rectW = 134;
    const rectH = 96;
    const rectX = 23;
    const rectY = 1076;
    ctx.save();
    ctx.fillStyle = '#FF595E';
    ctx.strokeStyle = 'black';
    ctx.beginPath()
    ctx.roundRect(rectX, rectY, rectW, rectH, [30])
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'top'
    ctx.font = '700 24px/24px Quicksand'
    const textSize = ctx.measureText('HOME')
    const xOffset = textSize.width / 2
    ctx.fillText('HOME', rectX + (rectW / 2 - xOffset), rectY + 13);
    this.homeIcon.draw(ctx);
    ctx.restore();
  }

  /** @param {CanvasRenderingContext2D} ctx */
  drawTutorial(ctx) {
    const rectW = 134;
    const rectH = 96;
    const rectX = 173;
    const rectY = 1076;
    ctx.save();
    ctx.fillStyle = this.tutorialBgColor;
    ctx.strokeStyle = 'black';
    ctx.beginPath()
    ctx.roundRect(rectX, rectY, rectW, rectH, [30])
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.fillStyle = this.tutorialTextColor;
    ctx.textBaseline = 'top'
    ctx.font = '700 24px/24px Quicksand'
    const textSize = ctx.measureText('TUTORIAL')
    const xOffset = textSize.width / 2
    const yOffset = (textSize.actualBoundingBoxAscent + textSize.actualBoundingBoxDescent) / 2
    ctx.fillText('TUTORIAL', rectX + (rectW / 2 - xOffset), rectY + (rectH / 2 - yOffset));
    ctx.restore();
  }

  /** @param {CanvasRenderingContext2D} ctx */
  drawInventory(ctx) {
    const rectW = 371;
    const rectH = 135;
    const rectX = 470;
    const rectY = 1145;
    ctx.save();
    ctx.fillStyle = '#AFB1B6';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.roundRect(rectX, rectY, rectW, rectH, [30, 0, 0, 0]);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.fillStyle = "#505050";
    ctx.font = '700 34px/24px Quicksand'
    ctx.textBaseline = 'top'
    const textSize = ctx.measureText('INVENTORY')
    const xOffset = textSize.width / 2
    ctx.fillText('INVENTORY', rectX + (rectW/2) - (xOffset) , rectY + 15);
    this.plusIcon.draw(ctx);
    ctx.restore();
    }

  /** @param {CanvasRenderingContext2D} ctx */
  draw(ctx) {
    this.drawMoney(ctx);
    this.drawDay(ctx);
    this.drawHome(ctx);
    this.drawInventory(ctx);
    this.drawTutorial(ctx);
  }

  getTimeDisplay(time) {
    const mins = Math.floor(time / 60)
    return `${mins}:${(time - mins * 60).toString().padStart(2, '0')}`
  }

  update(game) {
    this.currentMoney = game.money - game.moneyChange;
    this.timeDisplay = this.getTimeDisplay(game.timeRemaining);
    let { x, y } = GAME_GLOBALS.MOUSE_POS
    if ((x > 23
      && x < 23 + 134)
      && (y > 1076
      && y < 1076 + 96) && GAME_GLOBALS.CLICK) {
        GAME_GLOBALS.GAME_STATE = 'main'
        GAME_GLOBALS.CLICK = false;
        game.tutorialBox.isOpen = true;
        game.tutorialBox.currentStep = 1;
    }
    if (game.tutorialBox.isOpen) {
      this.tutorialBgColor = '#AFB1B6';
      this.tutorialTextColor = '#505050';
    } else {
      this.tutorialBgColor = '#45A0DA';
      this.tutorialTextColor = '#000000';
      if ((x > 173
        && x < 173 + 134)
        && (y > 1076
        && y < 1076 + 96) && GAME_GLOBALS.CLICK) {
          GAME_GLOBALS.CLICK = false;
          game.tutorialBox.isOpen = true;
          game.tutorialBox.currentStep = 1;
      }
    }
  }
}