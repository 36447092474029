import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { Grid } from '@material-ui/core';
import NavLinks from './NavLinks';

const useStyles = makeStyles({
	wrapper : {
		marginTop: 'auto',
		maxWidth: '100vw',
	},
	footer : {
		display: 'flex',
  	justifyContent: 'space-between',
  	alignItems: 'center',
  	padding: '2rem 4rem',
  	backgroundColor: '#FFEFF6',
  	boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
		fontSize: '1.5rem'
	},
	creditWrapper: {
		paddingLeft: '2rem',
		'& p' : {
			margin: "0"
		}
	},
	subText : {
		fontSize: '0.6rem',
	},
	mainText: {
		fontSize: '2rem',
		fontFamily: ['Vibes', 'cursive'],
		fontWeight: '400',
	},
	linkWrapper : {
		display: 'flex',
		justifyContent: 'space-evenly',
		textAlign: 'right',
		fontFamily: ['Raleway', 'sans-serif'],
		fontWeight: '300',
		fontStyle: 'normal',
		paddingLeft: '40px',
		'& a' : {
			textDecoration: 'none',
			color: 'black',
			padding: '7px',
			'&:hover' : {
				color:'#FF85C0',
				animation: '$linkHover 100ms ease-in-out',
				transform: 'translateY(-2px)',
				textShadow: '0px 5px 6px rgba(0,0,0,0.5)'
			}
		}
	},
	"@keyframes linkHover": {
		"0%" : {
			color: 'black',
			transform: 'translateY(0)'
		},
		"50%" : {
			color: '#FF85C0',
			transform: 'translateY(-1px)'
		},
		"100%" : {
			transform: 'translateY(-2px)',
			textShadow: '0px 5px 5px rgba(0,0,0,0.5)'
		}
	}
});

function Footer() {
	const classes = useStyles();
	return (
		<div className={classes.wrapper}>
			<div className={classes.footer}>
				<Grid container justifyContent='space-evenly' alignItems='center'>
					<Grid item lg={6} md={4} className={classes.creditWrapper}>
						<p className={classes.mainText}>Designed by Lindsey Erstad</p>
						<p className={classes.subText}>Developed by Dillon Jacobson | Icons by <a href='https://www.freepik.com' target="_blank" rel='noreferrer'>Freepik</a></p>
					</Grid>
					<Grid item lg={6} md={8}>
						<NavLinks />
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default Footer