import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Hero from '../components/Hero';
import SectionHeader from '../components/SectionHeader';
import SectionContentHeader from '../components/SectionContentHeader';
import PortfolioImageLink from '../components/PortfolioImageLink';
import globalUseStyles from '../helpers/GolbalClasses';
import useSetTitle from '../helpers/useSetTitle';

const useStyles = makeStyles((theme) => ({
  contactFormWrapper: {
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    fontFamily: 'raleway',
    fontWeight: '600',
    '& input': {
      fontFamily: 'raleway',
      fontWeight: '600',
      borderColor:theme.palette.pink.dark,
      lineHeight:'28px',
      margin:'1rem 0',
      width:'100%'
    },
    '& textarea':{
      fontFamily: 'raleway',
      fontWeight: '600',
      borderColor:theme.palette.pink.dark,
      lineHeight:'28px',
      margin:'1rem 0',
      width:'100%',
      borderWidth:'2px',
      height:'200px',
      '&:focus-visible':{
        borderColor:theme.palette.pink.dark+' !important',
      }
    }
  },
  contactFormSubmitButton:{
    fontFamily: 'raleway',
    fontWeight: '600',
    fontSize:'32px',
    lineHeight:'37px',
    color:'white',
    width:'100%',
    padding:'1rem 1.5rem',
    margin: '0',
    backgroundColor: theme.palette.pink.dark,
    border:'none',
    '&:hover' : {
      color:theme.palette.pink.dark,
      backgroundColor:'white',
      borderWidth:'3px',
      borderColor:theme.palette.pink.dark,
      borderStyle:'solid',
      margin:'-3px'
    }
  },
  portfolioSection:{
    [theme.breakpoints.up('lg')]:{

    }
  }

}));


function Home() {

  const classes = useStyles();
  const globalClasses = globalUseStyles();
	useSetTitle('Home');
  return (
    <div>
      <Hero/>
      <SectionHeader text='About' />
        <section className={globalClasses.sectionWrapper}>
          <Grid container>
            <Grid item sm={5}>  
                <SectionContentHeader text="HI, I'M LINDSEY ERSTAD!"/>
                <div className={globalClasses.sectionText}>
                  I am a Social Media Manager by day and a Creative Freelancer Extraordinaire by night! I have found that my true passion is helping others have more success by using my creative talents.
                  <br />
                  <br />
                  As a skilled designer, editor, and writer, I offer a variety of services all in one place, or rather, person!
                  <br />
                  <br />
                  Thank you for visiting my portfolio. You may be here because you were referred to me, are curious about my skills, or are looking for creative services to help boost your business. Any way you slice it, you are in the right place!
                </div>
            </Grid>
            <Grid item sm={2}></Grid>
            <Grid item sm={5}>  
                  <div className={classes.sectionImage}>
                    <img src='/headshot.png' alt='Headshot'></img>
                  </div>
            </Grid>
          </Grid>
        </section>
      <SectionHeader text='Explore' />
      <section className={globalClasses.sectionWrapper}>
        <Grid container columns={12} className={classes.portfolioSection}>
          <Grid item sm={6} lg={3}>
            <PortfolioImageLink image="/ui-icon.svg" text="User Interface" url="/user-interface"/>
          </Grid>
          <Grid item sm={6} lg={3}>
            <PortfolioImageLink image="/graphic-design.svg" text="Graphic Design" url="/graphic-design"/>
          </Grid>
          <Grid item sm={6} lg={3}>
            <PortfolioImageLink image="/video-editing.svg" text="Video Editing" url="/video-editing"/>
          </Grid>
          <Grid item sm={6} lg={3}>
            <PortfolioImageLink image="/podcasts.svg" text="Podcasts" url="/podcasts"/>
          </Grid>
        </Grid>
      </section>
      {/* <SectionHeader text='Contact' /> */}
      <section className={globalClasses.sectionWrapper} style={{display:'none'}}>
        <Grid container>
          <Grid item sm={0} md={1} lg={3}></Grid>
          <Grid item sm={12} md={10} lg={6}>
            <form className={classes.contactFormWrapper}>
              
              <input type='text' name='name' placeholder='Full Name'></input>
              <input type='email' name='email' placeholder='Email'></input>
              <textarea name='message' placeholder='Message'></textarea>
              <button type='submit' className={classes.contactFormSubmitButton}>
                SUBMIT
              </button>
            </form>
          </Grid>
          <Grid item sm={0} md={1} lg={3}></Grid>
        </Grid>
      </section>
    </div>

  );
}

export default Home;
