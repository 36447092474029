import Button, { IconButton } from "../components/Button";
import GameObject from "../GameObject";
import { GAME_GLOBALS } from "../Globals";

export default class MainMenu {

  objects = []
  buttons = []

  async init() {
    const initList = []
    console.log('Initializing Main Menu...');
    const logo = new GameObject(156, 207, 532, 332, 'game/logo.png');
    initList.push(logo.init());
    this.objects.push(logo);
    const apple = new GameObject(624, 76, 138, 102, 'game/apple.png');
    initList.push(apple.init());
    this.objects.push(apple);
    const orange = new GameObject(639, 508, 135, 111, 'game/orange.png');
    initList.push(orange.init());
    this.objects.push(orange);
    const blueberry = new GameObject(33, 552, 116, 90, 'game/blueberry.png');
    initList.push(blueberry.init());
    this.objects.push(blueberry);
    const pineapple = new GameObject(55, 888, 120, 236, 'game/pineapple.png');
    initList.push(pineapple.init());
    this.objects.push(pineapple);
    const watermelon = new GameObject(623, 985, 144, 131, 'game/watermelon.png');
    initList.push(watermelon.init());
    this.objects.push(watermelon);
    const banana = new GameObject(65, 40, 171, 146, 'game/banana.png');
    initList.push(banana.init());
    this.objects.push(banana);
    const continueBtn = new Button(250, 626, 336, 56, 'red', 'CONTINUE', 36, false);
    this.objects.push(continueBtn);
    const newGameBtn = new Button(250, 734, 336, 56, 'blue', 'NEW GAME', 36, true);
    newGameBtn.onClick = () => { GAME_GLOBALS.GAME_STATE = 'game' }
    this.objects.push(newGameBtn);
    const leaderboardBtn = new Button(250, 842, 336, 56, 'green', 'LEADERBOARD', 36, true);
    this.objects.push(leaderboardBtn);
    leaderboardBtn.onClick = () => { 
      this.leaderboardOpen = true
      leaderboardBtn.isActive = false;
      newGameBtn.isActive = false;
    }
    this.leaderboardOpen = false;
    this.leaderboardRect = {
      x:159,
      y:251,
      w:516,
      h:692,
    };
    this.leaderboardSrc = document.getElementById('leaderboard');
    this.leaderboardClose = new IconButton(623, 267, 33, 33, 'black', true, 'game/iconClose.png');
    this.leaderboardClose.onClick = () => {
      this.leaderboardOpen = false;
      leaderboardBtn.isActive = true;
      newGameBtn.isActive = true;
    }
    initList.push(this.leaderboardClose.init())
    await Promise.all(initList);
    console.log('Main Menu Initialized');
  }

  update() {
    this.objects.forEach((obj) => {
      obj.update()
    })
    if (this.leaderboardOpen) {
      this.leaderboardClose.update();
    }
  }

  /**
   * 
   * @param {CanvasRenderingContext2D} ctx 
   */
  draw(ctx) {
    ctx.save();
    ctx.fillStyle = 'white';
    ctx.strokeStyle = 'black'
    ctx.lineWidth = 4;
    ctx.beginPath();
    ctx.roundRect(0, 0, GAME_GLOBALS.WIDTH, GAME_GLOBALS.HEIGHT, [20]);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
    this.objects.forEach((obj) => {
      obj.draw(ctx);
    })
    if (this.leaderboardOpen) {
      ctx.drawImage(this.leaderboardSrc, this.leaderboardRect.x, this.leaderboardRect.y);
      this.leaderboardClose.draw(ctx);
    }
  }
  
  cleanup() {
    
  }
}
