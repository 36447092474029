import { makeStyles } from "@material-ui/core";

const globalUseStyles = makeStyles((theme) => ({
	sectionWrapper: {
    padding: '4rem 10rem',
    display: 'flex',
    justifyContent:'space-between',
    
    flexDirection:'column',
    [theme.breakpoints.down('md')]:{
      padding: '4rem 5rem'
    }
  },
	sectionText: {
    paddingTop: '1rem',
    fontFamily: 'raleway',
    fontWeight: '300',
    fontSize: '24px',
    alignItems:'center'
  },
  sectionImage: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
	sectionLink:{
		color: theme.palette.pink.dark,
		'&:hover': {
			animation: '$sectionLinkHover 100ms ease-in-out',
			transform: 'translateY(-2px)',
			textShadow: '0px 5px 6px rgba(0,0,0,0.5)',
			color: theme.palette.pink.darker
		}
	},
	"@keyframes sectionLinkHover": {
		"0%" : {
			color: theme.palette.pink.dark,
			transform: 'translateY(0)'
		},
		"50%" : {
			color:theme.palette.pink.darker,
			transform: 'translateY(-1px)'
		},
		"100%" : {
			transform: 'translateY(-2px)',
			textShadow: '0px 5px 5px rgba(0,0,0,0.5)'
		}
	},
}))

export default globalUseStyles;