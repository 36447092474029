import GameObject from "../GameObject";
import { GAME_GLOBALS } from "../Globals";
import Person from "./Person";

 export default class Background {
  async init() {
    const initList = [];
    this.background = new GameObject(0, 0, GAME_GLOBALS.WIDTH, GAME_GLOBALS.HEIGHT, 'game/background.png');
    this.background.setCrop({x: 600, y:0, w:GAME_GLOBALS.WIDTH * 1.5, h:GAME_GLOBALS.HEIGHT * 1.5});
    initList.push(this.background.init());

    this.person = new Person(320, 630, 160, 335, 'game/shopKeeper.png', null, null);
    initList.push(this.person.init()); 

    this.stand = new GameObject(180, 535, 475, 449, 'game/stand.png');
    initList.push(this.stand.init());
    await Promise.all(initList);
  }

  draw(ctx) {
    this.background.draw(ctx);
    this.person.draw(ctx);
    this.stand.draw(ctx);
  }
 }