export const peopleAnims = [
  {
    imgId: 'person1',
    animSpeed: 1000,
    yPos: 715,
    w:165,
    h:450,
    frames: 1,
    crop: {
        x: -10,
        y: 0,
        w: 165,
        h: 582,
    }
  },
  {
    imgId: 'person2',
    animSpeed: 90,
    yPos: 615,
    w:165,
    h:380,
    frames: 1,
    crop: {
        x: 0,
        y: 0,
        w: 203,
        h: 451,
    }
  },
  {
    imgId: 'person3',
    animSpeed: 100,
    yPos: 615,
    w:219,
    h:400,
    frames: 1,
    crop: {
        x: 0,
        y: 0,
        w: 239,
        h: 442,
    }
  },
]