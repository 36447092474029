import React from 'react'
import {makeStyles} from '@material-ui/core'
import {Parallax} from 'react-parallax'


const useStyles = makeStyles((theme) => ({
  heroImg : {
    position: 'absolute',
    width:'100%',
    height:'auto',
    top:'100%',
    [theme.breakpoints.down('md')]:{
      transform:'translateY(30%)'
    },
    [theme.breakpoints.only('lg')]:{
      transform:'translateY(10%)'
    },
    [theme.breakpoints.only('xl')]:{
      transform:'translateY(-5%)'
    },
    [theme.breakpoints.up('xl')]:{
      transform:'translateY(-3%)'
    }
  },
  heroStyle : {
    display: 'block',
    width: '100%',
    height:'calc(80vh - 115px)',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('xl')]:{
      height:'calc(100vh - 115px)'
    },
    [theme.breakpoints.down('md')]: {
      height:'calc(60vh - 115px)',
    }
  }

}));

function Hero() {
	const classes = useStyles();
	return (
    <Parallax bgImage='/hero.png' bgStyle={classes.heroImg} strength={-200}>
      <div className={classes.heroStyle}></div>
    </Parallax>
	)
}

export default Hero