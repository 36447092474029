import React from 'react'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	linkWrapper : {
		display: 'flex',
		justifyContent: 'space-evenly',
		textAlign: 'right',
		fontFamily: ['Raleway', 'sans-serif'],
		fontWeight: '300',
		fontStyle: 'normal',
		paddingLeft: '40px',
		'& a' : {
			textDecoration: 'none',
			color: 'black',
			'&:hover' : {
				animation: '$linkHover 100ms ease-in-out',
				transform: 'translateY(-2px)',
				textShadow: '0px 5px 6px rgba(0,0,0,0.5)',
				color: theme.palette.pink.dark
			}
		},
		fontSize: '1.5rem',
		[theme.breakpoints.down('md')]:{
			fontSize: '1rem'
		}
	},
	"@keyframes linkHover": {
		"0%" : {
			color: 'black',
			transform: 'translateY(0)'
		},
		"50%" : {
			color:theme.palette.pink.dark,
			transform: 'translateY(-1px)'
		},
		"100%" : {
			transform: 'translateY(-2px)',
			textShadow: '0px 5px 5px rgba(0,0,0,0.5)'
		}
	},
}));

function NavLinks() {
	
	const classes = useStyles();

	return (
		<div className={classes.linkWrapper}>
			<a href='/'>Home</a>
			<a href='/user-interface'>UX / UI</a>
			<a href='/graphic-design'>Graphics</a>
			<a href='/video-editing'>Videos</a>
			<a href='/podcasts'>Podcasts</a>
		</div>
	)
}

export default NavLinks