import Button, { IconButton } from "./Button";

export default class WholesaleMenu {

  constructor() {
    this.sprite = document.getElementById('wholesaleMenu');
    this.rect = {
      x: 96,
      y: 242,
      w: 643,
      h: 710,
    }
    this.isOpen = true;
    this.inventoryChange = {}
  }

  updateInventory(item, buy) {
    const keys = Object.keys(this.inventoryChange);
    if (!keys.includes(item)) {
      this.inventoryChange[item] = 0;
    }
    if (buy) this.inventoryChange[item] += 1;
    else this.inventoryChange[item] -= 1;
  }

  async init() {
    const initList = []
    const yStart = 388
    const cellHeight = 68
    const buyOffset = 507 + this.rect.x
    const iconSize = 28
    const plusIconOffset = buyOffset + 94
    const minusIconOffset = buyOffset + 12
    this.appleBuyButton = new IconButton(
      plusIconOffset,
      yStart + (cellHeight / 2 - iconSize / 2),
      iconSize,
      iconSize,
      'green',
      true,
      'game/plusIcon.png'
    );
    this.appleBuyButton.onClick = () => {this.updateInventory('apples', true)}
    initList.push(this.appleBuyButton.init())
    this.appleSellButton = new IconButton(
      minusIconOffset,
      yStart + (cellHeight / 2 - iconSize / 2),
      iconSize,
      iconSize,
      'red',
      false,
      'game/minusIcon.png'
    );
    this.appleSellButton.onClick = () => {this.updateInventory('apples', false)}
    initList.push(this.appleSellButton.init())
    this.bananaBuyButton = new IconButton(
      plusIconOffset,
      yStart + cellHeight + (cellHeight / 2 - iconSize / 2),
      iconSize,
      iconSize,
      'green',
      true,
      'game/plusIcon.png'
    );
    this.bananaBuyButton.onClick = () => {this.updateInventory('bananas', true)}
    initList.push(this.bananaBuyButton.init())
    this.bananaSellButton = new IconButton(
      minusIconOffset,
      yStart + cellHeight + (cellHeight / 2 - iconSize / 2),
      iconSize,
      iconSize,
      'red',
      false,
      'game/minusIcon.png'
    );
    this.bananaSellButton.onClick = () => {this.updateInventory('bananas', false)}
    initList.push(this.bananaSellButton.init())
    this.startButton = new Button(this.rect.x + 231, 884, 181, 50, 'blue', 'START DAY', 24, true);
    this.startButton.onClick = () => { this.isOpen = false }
    await Promise.all(initList);
  }

  update (game) {
    this.startButton.update();

    const hasStock = game.inventory.some((item) => {
      return item.stock > 0;
    });

    const boughtStock = Object.values(this.inventoryChange).some((item) => {
      return item > 0;
    })

    if ((hasStock || boughtStock) && !this.startButton.isActive) {
      this.startButton.isActive = true;
    } else if (!hasStock && !boughtStock && this.startButton.isActive) {
      this.startButton.isActive = false;
    }

    if (game.money - game.moneyChange - game.inventory[0].buy < 0) {
      if (this.appleBuyButton.isActive) this.appleBuyButton.setActive(false);
    } else if (!this.appleBuyButton.isActive) this.appleBuyButton.setActive(true);
    if (this.inventoryChange['apples'] > 0) {
      if (!this.appleSellButton.isActive) this.appleSellButton.setActive(true);
    } else if (this.appleSellButton.isActive) this.appleSellButton.setActive(false);

    if (game.money - game.moneyChange - game.inventory[1].buy < 0) {
      if (this.bananaBuyButton.isActive) this.bananaBuyButton.setActive(false);
    } else if (!this.bananaBuyButton.isActive) this.bananaBuyButton.setActive(true);
    if (this.inventoryChange['bananas'] > 0) {
      if (!this.bananaSellButton.isActive) this.bananaSellButton.setActive(true);
    } else if (this.bananaSellButton.isActive) this.bananaSellButton.setActive(false);

    this.appleBuyButton.update();
    this.appleSellButton.update();
    this.bananaBuyButton.update();
    this.bananaSellButton.update();
  }

  /** @param {CanvasRenderingContext2D} ctx */
  getTextOffset(ctx, text) {
    const measure = ctx.measureText(text);
    return {
      x: measure.width / 2,
      y: (measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent) / 2
    }
  }

  /** @param {CanvasRenderingContext2D} ctx */
  drawNumbers(ctx) {
    ctx.save()
    ctx.font = '700 24px/24px Quicksand'
    ctx.fillStyle = 'black';
    ctx.textBaseline = 'top'
    const zeroOffsets = this.getTextOffset(ctx, '0');
    const appleChangeText = this.inventoryChange['apples'] || 0;
    const appleOffsets = this.getTextOffset(ctx, appleChangeText)
    const bananaChangeText = this.inventoryChange['bananas'] || 0;
    const bananaOffsets = this.getTextOffset(ctx, bananaChangeText)
    const yStart = 388
    const cellHeight = 68
    const unitCellWidth = 106
    const buyCellWidth = 134
    const unitOffset = 132 + this.rect.x
    const buyOffset = 507 + this.rect.x
    ctx.fillText('0', unitOffset + unitCellWidth / 2 - zeroOffsets.x, yStart + (cellHeight / 2) - zeroOffsets.y);
    ctx.fillText('0', unitOffset + unitCellWidth / 2 - zeroOffsets.x, yStart + cellHeight + (cellHeight / 2) - zeroOffsets.y);
    ctx.fillText(appleChangeText, buyOffset + buyCellWidth / 2 - appleOffsets.x, yStart + (cellHeight / 2) - appleOffsets.y);
    ctx.fillText(bananaChangeText, buyOffset + buyCellWidth / 2 - bananaOffsets.x, yStart + cellHeight + (cellHeight / 2) - bananaOffsets.y);
    ctx.restore()
  }

  /** @param {CanvasRenderingContext2D} ctx */
  draw(ctx) {
    ctx.drawImage(this.sprite, 96, 242, 643, 710)
    this.startButton.draw(ctx);
    this.appleBuyButton.draw(ctx);
    this.appleSellButton.draw(ctx);
    this.bananaBuyButton.draw(ctx);
    this.bananaSellButton.draw(ctx);
    this.drawNumbers(ctx);
  }

  cleanup() {
    this.isOpen = true;
    this.inventoryChange = {};
    this.appleBuyButton.setActive(true);
    this.appleSellButton.setActive(false);
    this.bananaBuyButton.setActive(true);
    this.bananaSellButton.setActive(false);
  }

}