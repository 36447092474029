import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './screens/Home';
import UserInterface from './screens/UserInterface';
import Nav from './components/Nav'
import Footer from './components/Footer'
import { makeStyles } from '@material-ui/core/styles';
import Podcasts from './screens/Podcasts';
import VideoEditing from './screens/VideoEditing';
import Graphics from './screens/Graphics';
import FruitStand from './screens/FruitStand';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh'
  },
})



function AppRoutes() {
  const classes = useStyles();
  const location = useLocation();
  const noFooterRoutes = [
    '/',
  ]
  const noNavRoutes = [
    '/',
  ]

	return (
    <div className={classes.wrapper}>
      { !noNavRoutes.includes(location.pathname) && <Nav /> }
        <Routes>
          <Route path='/' element={<FruitStand/>} />
          {/* <Route path='/user-interface' element={<UserInterface />} />
          <Route path='/podcasts' element={<Podcasts />} />
          <Route path='/video-editing' element={<VideoEditing />} />
          <Route path='/graphic-design' element={<Graphics/>} />
          <Route path='/fruit-stand' element={<FruitStand/>} /> */}
        </Routes>
      { !noFooterRoutes.includes(location.pathname) && <Footer /> }

    </div>
	)
}

export default AppRoutes;