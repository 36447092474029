import Person from "../components/Person";
import TutorialBox from "../components/TutorialBox";
import { GAME_GLOBALS } from "../Globals";
import GameUI from "../components/GameUI";
import Background from "../components/Background";
import { getCurrentTimeSecs } from "../../helpers/currentTimeSecs";
import { peopleAnims } from "../components/peopleAnims";
import WholesaleMenu from "../components/Wholesale";
import DollarSign from "../components/DollarSign";
import Button from "../components/Button";

export default class Game {

  objects = [];

  constructor() {
    this.lastSpawn = null;
    this.spawnRate = 1.5;
    this.dayStart = 0;
    this.dayLength = 180;
    this.dayComplete = false;
    this.timeRemaining = 180;
    this.lastSpawn = 0;
    this.inventory = [
      {
        name: 'apples',
        stock: 0,
        buy: 0.25,
        sell: 0.5,
      },
      {
        name: 'bananas',
        stock: 0,
        buy: 0.5,
        sell: 1
      }
    ]
    this.soldThisDay = {
      apples:0,
      bananas:0,
    }
    this.day = 1;
    this.money = 25;
    this.moneyChange = 0;
    this.personY = 630;
  }

  async init() {
    console.log('Initializing Main Game...');
    const initList = [];
    
    this.background = new Background();
    initList.push(this.background.init());
    
    this.tutorialBox = new TutorialBox();
    initList.push(this.tutorialBox.init());

    this.ui = new GameUI();
    initList.push(this.ui.init(this));

    this.dayOverCloseBtn = new Button(327, 735, 181, 50, 'red', 'HOME', 24, false);
    this.dayOverCloseBtn.onClick = () => { GAME_GLOBALS.GAME_STATE = 'main' }
    this.dayOverPos = {
      x:108,
      y:374
    }
    this.dayOverSrc = document.getElementById('endOfDay');


    this.people = [];

    this.dollarSigns = [];

    this.peopleAssets = peopleAnims;

    this.peopleAssets = this.peopleAssets.map((asset) => {
      asset.sprite = document.getElementById(asset.imgId);
      asset.imgSrc = asset.sprite.src;
      return asset;
    });

    this.wholesale = new WholesaleMenu();
    initList.push(this.wholesale.init());

    await Promise.all(initList);
    console.log('Main Game Initialized');
  }

  triggerPurchase() {
    this.dollarSigns.push(new DollarSign());
  }

  checkInventory() {
    return this.inventory.reduce((newArr, item) => {
      if (item.stock > 0) newArr.push(item);
      return newArr;
    }, []).length > 0;
  }

  update() {
    this.ui.update(this);
    if (this.tutorialBox.isOpen) {
      this.tutorialBox.update();
      return;
    } else if (this.wholesale.isOpen) {
      this.wholesale.update(this);
      const keys = Object.keys(this.wholesale.inventoryChange);
      this.moneyChange = 0;
      keys.forEach((key) => {
        const item = this.inventory.find((obj) => {
          return obj.name === key 
        })
        this.moneyChange += item.buy * this.wholesale.inventoryChange[key];
      })
      return;
    } else if (this.dayComplete) {
      if (!this.dayOverCloseBtn.isActive) this.dayOverCloseBtn.isActive = true;
      this.dayOverCloseBtn.update();
    }

    if (!this.wholesale.isOpen && this.moneyChange) {
      this.money -= this.moneyChange;
      this.moneyChange = 0;
      const keys = Object.keys(this.wholesale.inventoryChange);
      keys.forEach((key) => {
        const item = this.inventory.find((obj) => {
          return obj.name === key 
        })
        item.stock += this.wholesale.inventoryChange[key];
      })
    }

    if (this.dayStart === 0){
      this.dayStart = getCurrentTimeSecs();
    }

    if (this.dayStart + this.dayLength < getCurrentTimeSecs() || !this.checkInventory()) {
      this.dayComplete = true;
    } else {
      this.timeRemaining = Math.floor(this.dayStart + this.dayLength - getCurrentTimeSecs());
    }

    if (!this.dayComplete && this.lastSpawn + this.spawnRate < getCurrentTimeSecs()) {
      if (Math.floor(Math.random() * 100) < 40){
        
        let assets = this.peopleAssets[Math.floor(Math.random() * this.peopleAssets.length)]
        this.people.push(new Person(-assets.w, assets.yPos, assets.w, assets.h, assets.imgSrc, assets.crop, assets.animSpeed, assets.frames, assets.sprite));
      }
      this.lastSpawn = getCurrentTimeSecs();
    }

    const setToDelete = [];

    this.people.forEach((person, idx) => {
      person.update(this);
      if (person.x > GAME_GLOBALS.WIDTH) {
        setToDelete.push(idx);
      }
    });

    this.people = this.people.reduce((arr, person, idx) => {
      if (!setToDelete.includes(idx)){
        arr.push(person);
      }
      return arr;
    }, []);

    this.dollarSigns.forEach((sign) => {
      sign.update();
    });

    this.dollarSigns = this.dollarSigns.reduce((arr, sign) => {
      if (!sign.setToDelete) arr.push(sign);
      return arr;
    }, []);
  }

  /** @param {CanvasRenderingContext2D} ctx */
  draw(ctx) {
    this.background.draw(ctx);
    this.ui.draw(ctx);

    this.people.forEach((person) => {
      person.draw(ctx);
    });

    this.dollarSigns.forEach((sign) => {
      sign.draw(ctx);
    })

    if (this.tutorialBox.isOpen) this.tutorialBox.draw(ctx);
    else if (this.wholesale.isOpen) this.wholesale.draw(ctx);
    else if (this.dayComplete) {
      ctx.drawImage(this.dayOverSrc, this.dayOverPos.x, this.dayOverPos.y);
      this.dayOverCloseBtn.draw(ctx);
    }
  }

  cleanup(){
    this.lastSpawn = null;
    this.dayStart = 0;
    this.dayComplete = false;
    this.timeRemaining = 180;
    this.lastSpawn = 0;
    this.inventory = [
      {
        name: 'apples',
        stock: 0,
        buy: 0.25,
        sell: 0.5,
      },
      {
        name: 'bananas',
        stock: 0,
        buy: 0.5,
        sell: 1
      }
    ]
    this.soldThisDay = {
      apples:0,
      bananas:0,
    }
    this.day = 1;
    this.money = 25;
    this.moneyChange = 0;
    this.people = [];
    this.dollarSigns = [];
    this.dayOverCloseBtn.isActive = false;
    this.tutorialBox.cleanup();
    this.wholesale.cleanup();
  }

}